<template>
  <div class="container">
    <div class="section columns is-fluid">
      <div class="mobile-view-nav has-background-white mb-5">
        <div
          class="is-flex is-justify-content-space-between is-align-items-center px-4 py-3"
          @click="openMenu =! openMenu"
        >
          <p class="menu__title has-text-weight-bold">Меню</p>
          <p>
            <font-awesome-icon class="drop" :icon="['fas', !openMenu ? 'fa-chevron-down' : 'fa-chevron-up']" />
          </p>
        </div>
        <ul v-if="openMenu" class="p-0 is-flex is-flex-direction-column is-align-self-flex-start">
          <router-link
            v-for="link in links"
            :key="link.url"
            tag="li"
            class="list-item-device p-2 has-text-centered"
            active-class="active"
            :to="link.url === '/' ? link.url : link.url + device_id"
            exact
          >
            <a class="item-link is-size-6">
              {{ link.title }}
              <span v-if="link.isActiveMessages && GetMessagesData.active_msg_count" class="is-badge ml-1">
                {{ GetMessagesData.active_msg_count > 99 ? '99+' : GetMessagesData.active_msg_count }}
              </span>
            </a>
          </router-link>
        </ul>
      </div>
      <div class="desktop-view-nav">
        <ul class="device-views mx-4 column p-0 has-background-white is-flex is-flex-direction-column is-align-self-flex-start">
          <router-link
            v-for="link in links"
            :key="link.url"
            tag="li"
            class="list-item-device px-2 py-2"
            active-class="active"
            :to="link.url === '/' ? link.url : link.url + device_id"
            exact
          >
            <a
              href="#"
              class="item-link is-size-6"
              style="white-space: nowrap"
            >
              {{ link.title }}
              <span v-if="link.isActiveMessages && GetMessagesData.active_msg_count" class="is-badge ml-1">
                {{ GetMessagesData.active_msg_count > 99 ? '99+' : GetMessagesData.active_msg_count }}
              </span>
            </a>
          </router-link>
        </ul>
      </div>
      <div class="device-mobile column has-background-white is-four-fifths is-relative">
        <router-view :key="$route.fullPath"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"

const LINKS = [
  { title: "Главная", url: "/" },
  { title: "Обзор", url: "/device/views/" },
  { title: "Активные сообщения", url: "/device/active-messages/", isActiveMessages: true },
  { title: "Архивные сообщения", url: "/device/archive-messages/" },
]

export default {
  props: ['device_id', 'deviceName'],
  data: () => ({
    openMenu: false,
    isMain: false,
    links: LINKS,
  }),
  computed: mapGetters(["GetMessagesData"]),
  watch: {
    GetMessagesData() {
      const config = this.GetMessagesData.config || {}

      this.links = [
        LINKS[0], LINKS[1],
        ...(config['trends'] ? [
          { title: "Тренды виброскорости", url: "/device/trends/vel/" },
          { title: "Тренды виброускорения", url: "/device/trends/acc/" },
          { title: "Тренды DKW", url: "/device/trends/dkw/" },
        ] : []),
        ...(config['trend_specters'] ? [{ title: "Тренды полос спектров", url: "/device/trends-spec/" }] : []),
        ...(config['specters'] ? [{ title: "Спектры", url: "/device/spectors/" }] : []),
        // ...(config['trend_specters'] ? [{ title: "Тренды полос спектров", url: "/device/trends-spec/" }] : []),
        ...(config['oils'] ? [{ title: "Тренды счетчиков частиц в масле", url: "/device/trends-oil/" }] : []),
        LINKS[2], LINKS[3]
      ]
    },
  },
  mounted() {
    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
    // this.ChangeNavName(this.$router.history.current.meta.title)
    this.ChangeNavName(this.deviceName || 'Обзор')
  },
  methods: {
    ...mapMutations(["ChangeNavName"]),
  },
};
</script>

<style scoped>
.item-link {
  color: #0091ff;
}

.active a {
  color: black;
  font-size: 17px;
  font-weight: 700;
  background-color: #edf2fa;
}

.list-item-device:hover {
  background-color: #edf2fa;
}

.list-item-device.active {
  background-color: #edf2fa;
}

@media (max-width: 1024px) {
  .device-mobile {
    margin-bottom: 50px;
  }

  .device-views {
    text-align: center;
    margin-bottom: 50px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.is-badge {
  display: inline-flex;
  background-color: #0091ff;
  border-radius: 100px;
  min-width: 24px;
  height: 24px;
  width: auto;
  padding-inline: 6px;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
}
</style>
